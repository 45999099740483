<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">

      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="contributionsRanges"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadContributionsRanges"
            >
              <template
                v-slot:[`item.initialIndex`]="{ item }"
              >
                {{ item.initialIndex | number(10) }}
              </template>

              <template
                v-slot:[`item.finalIndex`]="{ item }"
              >
                {{ item.finalIndex | number(10) }}
              </template>

               <template
                v-slot:[`item.value`]="{ item }"
              >
                {{ item.value | currency }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-contribution-range
      v-model="showDialoContributionRange"
      :contribution-range-id="editId"
      @save="loadContributionsRanges"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

  </div>
</template>

<script>
  import contributionsRangesApi from '@/api/contributions-ranges'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogContributionRange: () => import('@/components/dialog/DialogContributionRange'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialoContributionRange: false,
        showDialogDelete: false,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Índice Inicial', value: 'initialIndex' },
          { text: 'Índice Final', value: 'finalIndex' },
          { text: 'Ano', value: 'year' },
          { text: 'Valor', value: 'value' },
          { text: '', value: 'actions', align: 'right' },
        ],
        contributionsRanges: [],
        pagination: {},
        editId: null,
        deleteId: null,
      }
    },

    methods: {
      async loadContributionsRanges () {
        try {
          this.loading = true

          const response = await contributionsRangesApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.contributionsRanges = response.data.contributionsRanges
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (contributionRange = null) {
        this.editId = contributionRange ? contributionRange.id : null
        this.showDialoContributionRange = true
      },

      async clickDeleteItem (contributionRange) {
        this.deleteId = contributionRange.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await contributionsRangesApi.delete(this.deleteId)

          this.loadContributionsRanges()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
